import { createBrowserRouter } from 'react-router-dom'

import * as Views from './views'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/stylesheets/nhuvo.css'

const Router = createBrowserRouter([
  {
    path: '/',
    element: <Views.Root />,
    children: [
      {
        index: true,
        element: <Views.Home />,
      },
    ],
  },
])

export default Router
